.topnavbar{
background-color: #0D6EFD;
color: #fff;
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 50px;
position: sticky;
top: 0;
z-index: 2;
}

.icon a{
    text-decoration: none;
    color: #fff;

}
.c-img{
    
}
.c-img img{
    width: 100%;
    height: 100%;
    object-fit:cover;
}
.icon i{
    padding: 0 10px;
}
.icon{
    padding-right: 20px;
}
.icon:hover span{
    color: red;
}
.user{
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    position: relative;
}
.user i{
    background-color: #f2f2f2;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px 10px;
    align-items: center;
    text-align: center;
    color: black;
    display: flex;
    position: relative;
}
.count{
    width: 20px;
    height: 20px;
    background-color: red;
    position: absolute;
    top: -10px;
    right: 2px;
    z-index: 1;
    border-radius: 50%;
    color: #000;
    font-size: 14px;
    font-weight: bold;
    margin-top: 2px;
}
.bg-images{
    background: url(./assest/images/bg.jpg);
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    animation: bg-animate infinite 10s;
    background-repeat: no-repeat;
      
}
@keyframes bg-animate{
0%{
    background: url(./assest/images/bg.jpg); 
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
25%{
    background: url(./assest/images/bg-2.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
50%{
    background: url(./assest/images/bg-3.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
75%{
    background: url(./assest/images/bg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
100%{
    background: url(./assest/images/bg-2.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
}
.navs{
    position: fixed;
    z-index: 10;
}
footer{
    background-color: black;
    
}

.logo{
    width: 200px;

}
.foot-cs{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}
.list{
    list-style: none;
    color: white;
    font-size: 20px;

}
.list li{
    cursor: pointer;

}

.list li:hover{
    color: blue;
}
.social{
    text-align: center;
    margin-top: 20px;
}
.social i{
    font-size: 30px;
    margin-right: 20px;
}
.banner-text{
    padding: 250px 0px;
    text-align: center;  
}
.banner-sub{
color:white;
font-weight: bold;
font-size: 40px;

}
.logo-ban{
    width: 400px;
    background-color:rgb(0,0,0,0.5);
    
}
.banner-head{
    font-size: 50px;
    font-weight: 800;
}
.banner-head-1{
    font-size: 30px;
    font-weight:700;

}


/*------ medai query---------------- */
@media screen and (max-width:1000px){
    .bg-images{
        width: 100%;
        height: 100%;
        margin-top: 17%;
       
    }
    .banner-text{
        padding: 30px 0px !important;
    }
    .fott{
        text-align: center;
        align-items: center;

    }
}
/*------ medai query---------------- */
