.service{
    margin-top: 5%;
    padding: 20px 0px;
}
.b-img{
    height: 90vh;
    padding: 30px 10px;

}
.b-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}
.text-over{
    font-size: 70px;
    font-weight: 900;
    text-transform: uppercase

}
.chef-2{
    margin-top: 5%;
    height: 90vh;
}
.chef-2 img{
    width: 100%;
    height: 100%;
}
.chef-spl{
    background-color: #f2f2f2;
    padding: 50px 0px;
}
.bread-1{
    background-color: #f2f2f2;
    padding: 50px;
   
}
.banner{
    width: 100%;
    height: 50vh;

}
.banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.about{
    
}
.bread{

    padding: 20px 0px;
}
.br-img{
    width: 400px;
    height: 400px;
}
.br-img img{
    width: 100%;
    height: 100%;
}
.contact{
    padding: 50px 0px;
}
.contact iframe{
    width: 100%;
    height: 100vh;
   
}
.icons{
    background-color: blueviolet;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    align-items: center;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 10px;
}
.icon-1{
    background-color: blueviolet;
}
.icon-2{
    background-color: lightcoral;
}

.icon-3{
    background-color: lightgreen;
}
.icons i{
    color: white;
    font-size: 20px;
}
.tesxt h2{
    font-size: 30px;
}



/* ----------media query------- */
@media screen and (max-width:1000px){
    .bre{
        width: 340px;
    } 
    .mob-view{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        
    }
    .mob-view button{
        margin: 5px;
        
    }
}

/* ----------media query------- */