.img-cart{
    width: 100px;
    height: 100px;
}
.cart-sd{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width:1000px){
    .img-cart{
        width: 50px;
        height: 50px;
    }
}